import axios from 'axios';

const API_URL = 'https://www.srb-unainvestigacion.org:3000/taller';

// Obtener todos los talleres
export const getTalleres = async () => {
    try {
        const response = await axios.get(`${API_URL}/GetTaller`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// Guardar un nuevo taller
export const saveTaller = async (taller) => {
    try {
        const response = await axios.post(`${API_URL}/SaveTaller`, taller);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// Actualizar un taller
export const updateTaller = async (taller) => {
    try {
        const response = await axios.put(`${API_URL}/UpdateTaller`, taller);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

// Eliminar un taller (cambio de estado)
export const deleteTaller = async (Tal_Id) => {
    try {
        const response = await axios.delete(`${API_URL}/DeleteTaller`, {
            data: { Tal_Id }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
