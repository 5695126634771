import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { guardarDatos, editarDatos } from '../../Service/GeneralService';

const Modal = ({ prefijo, tabla, isOpen, onClose, fields, onAdd, onEdit }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const initialData = {};
    fields.forEach(field => {
      initialData[field.name] = field.value || '';
    });
    setFormData(initialData);
  }, [fields]);

  const handleChange = (fieldName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    const formDataWithoutId = { ...formData };
    delete formDataWithoutId[`${prefijo}_Id`];

    if (formData[`${prefijo}_Id`]) {
      editarDatos(prefijo, tabla, formDataWithoutId, formData[`${prefijo}_Id`]).then(() => {
        onEdit(formData);
      });
    } else {
      guardarDatos(tabla, formDataWithoutId);
      onAdd(formDataWithoutId);
    }

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Elemento guardado exitosamente",
      showConfirmButton: false,
      timer: 1500
    });
    
    onClose();
  };

  const filteredFields = fields
    .filter(field => field.name !== `${prefijo}_Id`)
    .map(field => ({
      ...field,
      label: field.label.replace(`${prefijo}_`, ''), // Elimina el prefijo
    }));

  const dialogFooter = (
    <div>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={onClose} style={{ marginRight: '10px', borderRadius: '12px' }} />
      <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={handleSubmit} style={{ borderRadius: '12px', width: '130px' }}/>
    </div>
  );

  return (
    <Dialog
      className="p-fluid"
      header="Crear o Editar nuevo registro"
      visible={isOpen}
      onHide={onClose}
      footer={dialogFooter}
      breakpoints={{ '960px': '75vw', '640px': '100vw' }}
      style={{ width: '32rem' }}
    >
      <form>
        {filteredFields.map(field => (
          <div key={field.name} className="p-field">
            <label htmlFor={field.name}>{field.label}</label>
            {field.name === `${prefijo}_Descripcion` ? (
              <InputTextarea
                id={field.name}
                type={field.type || 'text'}
                value={formData[field.name] || ''}
                onChange={e => handleChange(field.name, e.target.value)}
                style={{ width: '100%' }} // Hace que se ajuste al contenedor
              />
            ) : (
              <InputText
                id={field.name}
                type={field.type || 'text'}
                value={formData[field.name] || ''}
                onChange={e => handleChange(field.name, e.target.value)}
                style={{ width: '100%' }} // Hace que se ajuste al contenedor
              />
            )}
          </div>
        ))}
      </form>
    </Dialog>
  );
};

export default Modal;
