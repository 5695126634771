import React, { useEffect, useState } from "react";
import "./VideoRenderer.css";
import { getVideos } from "../../Service/VideoService";
import VideoCard from "../VideoCard/VideoCard";
import { Dialog } from "primereact/dialog";

const VideoRenderer = () => {
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [loading, setLoading] = useState(true);

    // Extrae el ID de YouTube del URL.
    const extractYouTubeId = (url) => {
        const regex = /(?:youtu\.be\/|youtube\.com\/(?:.*v\/|.*\?v=|.*embed\/|.*user\/[^/]*\/videos\/|.*videos\/)|watch\?v=|&v=|watch\?.*#.*\/v\/|watch\?.*v=|^v\/)([^"&?\/\s]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getVideos();
            const formattedVideos = data.map(({ Vid_Id, Vid_Nombre, Vid_Descripcion, Vid_Url }) => ({
                id: Vid_Id,
                title: Vid_Nombre,
                description: Vid_Descripcion,
                youtubeId: extractYouTubeId(Vid_Url),
            }));
            setVideos(formattedVideos);
            setLoading(false);
        };
        fetchData();
    }, []);

    const handleWatchVideo = (video) => {
        setSelectedVideo(video);
    };

    const handleCloseModal = () => {
        setSelectedVideo(null);
    };

    const sections = [
        {
            title: "¿Qué tipo de videos ofrecemos?",
            content: "Ofrecemos una variedad de videos educativos y entretenidos que cubren diferentes temas y áreas de interés. Desde tutoriales y guías hasta contenido inspirador y educativo.",
        },
        {
            title: "Cómo ver nuestros videos",
            content: "Puedes explorar nuestra colección de videos directamente desde esta página. Haz clic en el video de tu interés para reproducirlo en nuestro reproductor integrado.",
        },
        {
            title: "Beneficios de ver nuestros videos",
            content: "Nuestros videos están diseñados para proporcionarte contenido de alta calidad que te ayudará a aprender y disfrutar. Además, puedes verlos en cualquier momento y desde cualquier lugar.",
        },

    ];

    if (loading) {
        return (
            <div className="loading">
                Cargando videos...
            </div>
        );
    }

    return (
        <div className="video-renderer-container">
            <h1 className="menu-title">Explora Nuestros Videos</h1>
            
            {/* Sección de información general */}
            <div className="info-sections">
                {sections.map((section, index) => (
                    <div key={index} className="info-section">
                        <h2 className="section-title">{section.title}</h2>
                        <p className="section-content">{section.content}</p>
                    </div>
                ))}
            </div>

            {/* Sección de videos */}
            <div className="video-cards">
                {videos.length > 0 ? (
                    videos.map((video, index) => (
                        <VideoCard
                            key={index}
                            title={video.title}
                            description={video.description}
                            onClick={() => handleWatchVideo(video)}
                            thumbnail={`https://img.youtube.com/vi/${video.youtubeId}/hqdefault.jpg`}
                        />
                    ))
                ) : (
                    <div className="no-results">No se encontraron videos</div>
                )}
            </div>

            {selectedVideo && (
                <Dialog
                    header={selectedVideo.title}
                    visible={!!selectedVideo}
                    style={{ width: '80vw' }}
                    onHide={handleCloseModal}
                >
                    <iframe
                        className="iframe-video"
                        src={`https://www.youtube.com/embed/${selectedVideo.youtubeId}`}
                        title={selectedVideo.title}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </Dialog>
            )}
        </div>
    );
};

export default VideoRenderer;
