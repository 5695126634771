import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import Swal from 'sweetalert2';
import { obtenerDocumentos, obtenerFotos, guardarDocumento, eliminarDocumento, descargarDocumento } from '../../Service/DocumentsService';
import { InputText } from 'primereact/inputtext';
import './MaintenanceDocuments.css';

const MaintenanceDocument = () => {
    const [pictureList, setPictureList] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [file, setFile] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [filterValue, setFilterValue] = useState('');
    const [tipoArchivo, setTipoArchivo] = useState('images'); // 'images' o 'uploads'
    const fileUploadRef = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        cargarArchivos();
    }, []);

    const cargarArchivos = async () => {
        try {
            const fotos = await obtenerFotos();
            const documentos = await obtenerDocumentos();
            setPictureList(fotos);
            setDocumentList(documentos);
        } catch (error) {
            console.error("Error al cargar archivos:", error);
        }
    };

    const handleDelete = async (file, tipo) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, bórralo',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await eliminarDocumento(file, tipo);
                    cargarArchivos();
                    Swal.fire('Eliminado', 'El archivo ha sido eliminado.', 'success');
                } catch (error) {
                    console.error('Error eliminando el archivo:', error);
                    Swal.fire('Error', 'Hubo un error al eliminar el archivo.', 'error');
                }
            }
        });
    };

    const handleNew = () => {
        setOpenModal(true);
    };

    const saveDocument = async () => {
        if (!file || file.length === 0) {
            Swal.fire('Error', 'Debes seleccionar al menos un archivo.', 'error');
            return;
        }

        const formData = new FormData();
        file.forEach((file) => {
            formData.append('file', file);
        });

        try {
            await guardarDocumento(formData, tipoArchivo);
            cargarArchivos();
            setOpenModal(false);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Archivo guardado exitosamente",
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            console.error('Error subiendo el archivo:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const downloadFile = async (file, tipo) => {
        try {
            await descargarDocumento(file, tipo);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            Swal.fire('Error', 'No se pudo descargar el archivo.', 'error');
        }
    };

    const dialogFooter = (
        <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={handleCloseModal} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={saveDocument} />
        </div>
    );

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        if (!files || files.length === 0) return;

        setFile([...file, ...files]);

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const value = totalSize / 1000000;
        const formattedValue = fileUploadRef?.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', width: '100%' }}>
                {chooseButton}
                {cancelButton}
                <div className="flex align-items-center gap-2 ml-auto">
                    <span>{formattedValue} / 50 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }} />
                </div>
            </div>
        );
    };

    const onFilterChange = (e) => {
        setFilterValue(e.target.value);
    };

    return (
        <div className="Component-datatable">
            <div className="data-table-header">
                <InputText
                    value={filterValue}
                    onChange={onFilterChange}
                    placeholder="Filtrar por nombre..."
                    className="p-inputtext-sm"
                />

                <Button
                    label="Nuevo"
                    icon="pi pi-plus"
                    severity="success"
                    onClick={handleNew}
                    className="p-button"
                />

                <div className="file-type-toggle">
                    <Button
                        label="Imágenes"
                        className={`p-button-${tipoArchivo === 'images' ? 'primary' : 'secondary'}`}
                        onClick={() => setTipoArchivo('images')}
                    />
                    <Button
                        label="Documentos"
                        className={`p-button-${tipoArchivo === 'uploads' ? 'primary' : 'secondary'}`}
                        onClick={() => setTipoArchivo('uploads')}
                    />
                </div>
            </div>

            <DataTable
                value={(tipoArchivo === 'images' ? pictureList : documentList).filter(item => item.includes(filterValue))}
                stripedRows
                paginator
                rows={5}
                showGridlines
                emptyMessage={`No hay ${tipoArchivo === 'images' ? 'imágenes' : 'documentos'} disponibles`}
                style={{ width: '90%', margin: '0 auto', borderRadius: '10px' }}
            >
                <Column header="Nombre" body={(rowData) => rowData} />
                <Column
                    header="Descargar"
                    body={(rowData) => (
                        <Button
                            icon="pi pi-download"
                            className="p-button-rounded p-button-info"
                            onClick={() => downloadFile(rowData, tipoArchivo)}
                        />
                    )}
                />
                <Column
                    header="Eliminar"
                    body={(rowData) => (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning"
                            onClick={() => handleDelete(rowData, tipoArchivo)}
                        />
                    )}
                />
            </DataTable>

            <Dialog header="Nuevo Archivo" footer={dialogFooter} visible={openModal} onHide={handleCloseModal}>
                <FileUpload
                    ref={fileUploadRef}
                    name="demo[]"
                    multiple
                    maxFileSize={50000000}
                    onSelect={onTemplateSelect}
                    headerTemplate={headerTemplate}
                    chooseLabel="Elegir"
                    cancelLabel="Cancelar"
                    uploadLabel="Subir"
                    style={{ width: '100%' }}
                />

            </Dialog>
        </div>
    );
};

export default MaintenanceDocument;
