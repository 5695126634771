import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "./Menu.css";
import taller from "../../Recursos/icono_taller.png";
import tutoria from "../../Recursos/icono_tutoria.png";
import persona from "../../Recursos/icono_persona.png";
import roles from "../../Recursos/icono_roles.png";
import parametros from "../../Recursos/icono_parametros.png";
import tutor from "../../Recursos/icono_tutor.png";
import video from "../../Recursos/icono_video.png";
import documento from "../../Recursos/icono_documento.png";
import { useAuth } from "../AuthContext";


import Card from "../Card/Card";

const MenuTutor = () => {
    const navigate = useNavigate();
   
    
     const { token } = useAuth();

     if (!token) {
         navigate("/LoginPage");
     }

    const opcionesMenu = [
        {
            nombre: "Talleres",
            imagen: taller,
            color: "#AA98A9",
            onClick: () => navigate('/MantenimientoTallerPage'),
        },
        {
            nombre: "Tutorias",
            imagen: tutoria,
            color: "#F9A825",
            onClick: () => navigate("/MantenimientoTutoriasPage"),
        },
       
        {
            nombre: "Parametros",
            imagen: parametros,
            color: "#42DC98",
            onClick: () => navigate("/MantenimientoParametroPage"),
        },

        {
            nombre: "Tutores",
            imagen: tutor,
            color: "#E262E8",
            onClick: () => navigate("/MantenimientoTutorPage"),
        },

        {
            nombre: "Videos",
            imagen: video,
            color: "#E8D462",
            onClick: () => navigate("/MantenimientoVideoPage"),
        },

        {
            nombre: "Documentos",
            imagen: documento,
            color: "#62B5E8",
            onClick: () => navigate("/MantenimientoDocumentoPage"),
        }

    ];

    return (
        <div className="menu-page">
        {opcionesMenu.map((opcion, index) => (
          <Card key={index} {...opcion} />
        ))}
      </div>
    );
}

export default MenuTutor;