import axios from 'axios';

export const getAllTutorias = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/tutorias/GetAllTutorias');
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

export const postTutoria = async (tutoria) => {
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/tutorias/PostTutoria', tutoria);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const putTutoria = async (tutoria) => {
    try {
        const response = await axios.put('https://www.srb-unainvestigacion.org:3000/tutorias/PutTutoria', tutoria);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}



export const getTutorias = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/tutorias/GetTutoria');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


export const GetNoDisponibilidadById = async (id) => {

    try {
      const response = await axios.get("https://www.srb-unainvestigacion.org:3000/tutorias/GetNoDisponibilidadById/"+id);
      return response.data;
        
    } catch (e) {
      console.error(e);
    }
  };

export const GetDisponibilidadById = async (id, dia, fecha) => {
    try {
        const response = await axios.get("https://www.srb-unainvestigacion.org:3000/tutorias/GetDisponibilidadById/"+id+"/"+dia+"/"+fecha);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const PostAgendarTutoria = async (dia, hora, idTutoria, nombre, correo, tutoria) => {
    try {
        const response = await axios.post("https://www.srb-unainvestigacion.org:3000/tutorias/PostAgenda", {
            dia: dia, 
            hora: hora, 
            IdTutoria: idTutoria,
            nombre: nombre, 
            correo: correo, 
            tutoria: tutoria
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const GetAgenda = async (id) => {
    try {
        const response = await axios.get("https://www.srb-unainvestigacion.org:3000/tutorias/GetAgenda/"+id);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}    

export const deleteAgendaItem = async (id) => { 
    try {
        const response = await axios.delete("https://www.srb-unainvestigacion.org:3000/tutorias/DeleteAgenda/"+id);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}   
