import React, { useEffect, useState } from 'react';
import Navbar from '../Componentes/Navbar/Navbar';
import Carusel from '../Componentes/Carusel/Carusel';
import Footer from "../Componentes/Footer/Footer";
import { getParametros } from '../Service/ParametrosService';
import { obtenerDocumentos, obtenerFotos, descargarDocumento } from '../Service/DocumentsService';
import './styles/Inicio.css';

export function PaginaInicial() {
  window.myGlobalLoggin = false;
  const [parametros, setParametros] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [imagenes, setImagenes] = useState([]);
  const [mostrarDocumentos, setMostrarDocumentos] = useState(false);
  const [mostrarImagenes, setMostrarImagenes] = useState(false);

  useEffect(() => {
    getParametros().then(setParametros);
    obtenerDocumentos().then(setDocumentos);
    obtenerFotos().then(setImagenes);
  }, []);

  const handleDescargar = (file, tipo) => {
    descargarDocumento(file, tipo);
  };

  if (parametros.length === 0) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Cargando...</p>
      </div>
    );
  }
  const bienvenida = parametros.find(item => item.Par_Nombre === 'Bienvenida');
  const principio1 = parametros.find(item => item.Par_Nombre === 'Pensamiento crítico');
  const principio2 = parametros.find(item => item.Par_Nombre === 'La empatía');
  const principio3 = parametros.find(item => item.Par_Nombre === 'El Diálogo');
  const principio4 = parametros.find(item => item.Par_Nombre === 'Potenciando voces');
  const principio5 = parametros.find(item => item.Par_Nombre === 'Integridad académica');

  return (
    <>
      <div>
        <Navbar />
        <Carusel />
        <section className="inicioStyles">
          <div className="content-container">
            <h2 className="tituloStyles">¡Bienvenidos al Centro de Escritura!</h2>
            <p className="descripcionStyles">
              {bienvenida.Par_Descripcion}
            </p>

            <div className="serviciosStyles">
              <h3>El servicio de aprendizaje incluye:</h3>
              <ul>
                <li>Tutorías individuales entre pares para proyectos de cualquier disciplina.</li>
                <li>Talleres sobre la escritura académica.</li>
                <li>Talleres sobre distintas etapas de los proyectos de investigación.</li>
                <li>Talleres sobre la búsqueda y evaluación de fuentes de información.</li>
                <li>Recursos informativos sobre la escritura académica.</li>
              </ul>
            </div>

            <div className="participaStyles">
              <p>
                No requieres de un trabajo terminado para recurrir a la ayuda de estudiantes tutores en el Centro de Escritura. Puede acudir con ideas, notas, o borradores.
              </p>
            </div>

            <div className="razonesStyles">
              <p>
                ¿Preguntas adicionales? Puede escribir al correo: centrodeescriturasrb@una.cr
              </p>
            </div>

            <div className="contactoStyles">
              <p>
                Síguenos en redes sociales para obtener información sobre consejos de escritura, recursos, actualizaciones, anuncio de talleres y más.
              </p>
            </div>

            <div className="principiosStyles">
              <h3>Principios que guían el Centro de Escritura:</h3>
              <ul className="principios-list">
                <li>
                 <span>  <strong>{principio1?.Par_Nombre}:</strong>  {principio1?.Par_Descripcion}</span>
                </li>
                <li>
                  <span><strong>{principio2?.Par_Nombre}:</strong>  {principio2?.Par_Descripcion}</span>
                </li>
                <li>
                  <span> <strong>{principio3?.Par_Nombre}:</strong> {principio3?.Par_Descripcion}</span>
                </li>
                <li>
                  <span><strong>{principio4?.Par_Nombre}:</strong>  {principio4?.Par_Descripcion}</span>
                </li>
                <li>
                  <span><strong>{principio5?.Par_Nombre}:</strong>  {principio5?.Par_Descripcion}</span>
                </li>
              </ul>
            </div>

              {/* Sección de Documentos */}
              <div
              className="documentosStyles"
              onClick={() => setMostrarDocumentos(!mostrarDocumentos)}
            >
              <h3>Documentos</h3>
              <p>Explora nuestros recursos y guías descargables para mejorar tu escritura académica.</p>
              <ul className={`listaDocumentos ${mostrarDocumentos ? 'mostrar' : ''}`}>
                {documentos.map(doc => (
                  <li key={doc} onClick={() => handleDescargar(doc, 'uploads')}>
                    {doc}
                  </li>
                ))}
              </ul>
            </div>

            {/* Sección de Imágenes */}
            <div
              className="imagenesStyles"
              onClick={() => setMostrarImagenes(!mostrarImagenes)}
            >
              <h3>Imágenes</h3>
              <p>Descubre nuestras infografías, esquemas y otros recursos visuales para potenciar tu aprendizaje.</p>
              <ul className={`listaImagenes ${mostrarImagenes ? 'mostrar' : ''}`}>
                {imagenes.map(img => (
                  <li key={img} onClick={() => handleDescargar(img, 'images')}>
                    {img}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <Footer parametros={parametros} />
      </div>
    </>
  );
}
