import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getPersonas = async () => {
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/personas/GetPersonas');
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const postPersona = async (persona) => {
    console.log("Holaaa",cookies.get('token'));
    try {
        const response = await axios.post('https://www.srb-unainvestigacion.org:3000/personas/PostPersona', persona, {
            headers: {
                Authorization: cookies.get('token')
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


export const obtenerCorreosAdmin = async () => {   
    try {
        const response = await axios.get('https://www.srb-unainvestigacion.org:3000/personas/GetCorreos', {
        });
        return response.data;

    } catch (error) {
        console.error(error);
    }
}   


export const putPersona = async (persona) => {
    try {
        const response = await axios.put('https://www.srb-unainvestigacion.org:3000/personas/PutPersona', persona, {
            headers: {
                Authorization: cookies.get('token')
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


