import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Cookies from 'universal-cookie';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './Navbar.css';

const Navbar = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const checkLoginStatus = () => {
            setIsLoggedIn(!!cookies.get('token'));
        };

        checkLoginStatus();
        const intervalId = setInterval(checkLoginStatus, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        logout();
        cookies.remove('token', { path: '/' });
        navigate("/");
    };

    const menuItems = [
        { label: 'Inicio', path: "/", icon: 'pi pi-home' },
        { label: 'Acerca de', path: "/AcercaDe", icon: 'pi pi-info-circle' },
        {
            label: 'Servicios y recursos', icon: 'pi pi-inbox', subItems: [
                {
                    label: 'Servicios', icon: 'pi pi-fw pi-cog', subItems: [
                        { label: 'Talleres', path: "/Talleres", icon: 'pi pi-calendar' },
                        { label: 'Tutorías', path: "/Tutorias", icon: 'pi pi-users' }
                    ]
                },
                {
                    label: 'Recursos', icon: 'pi pi-fw pi-folder-open', subItems: [
                        { label: 'Videos', path: "/Pagina_Videos", icon: 'pi pi-video' },
                       // { label: 'Documentos', path: "/DocumentPage", icon: 'pi pi-file-pdf' }
                    ]
                }
            ]
        },
    ];

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="navbar-container">
            <div className="navbar-left">
                <button className="navbar-hamburger" onClick={toggleMenu}>
                    <i className="pi pi-bars"></i>
                </button>
                <div className={`navbar-items ${menuOpen ? 'open' : ''}`}>
                    {menuItems.map((item, index) => (
                        <div key={index} className="navbar-item">
                            <button onClick={() => item.subItems ? null : navigate(item.path)} className="navbar-button">
                                <i className={item.icon}></i>
                                {item.label}
                            </button>
                            {item.subItems && (
                                <div className="navbar-dropdown">
                                    {item.subItems.map((subItem, subIndex) => (
                                        <div key={subIndex} className="navbar-dropdown-item">
                                            <button onClick={() => subItem.subItems ? null : navigate(subItem.path)} className="navbar-button">
                                                <i className={subItem.icon}></i>
                                                {subItem.label}
                                            </button>
                                            {subItem.subItems && (
                                                <div className="navbar-subdropdown">
                                                    {subItem.subItems.map((nestedItem, nestedIndex) => (
                                                        <button key={nestedIndex} onClick={() => navigate(nestedItem.path)} className="navbar-button">
                                                            <i className={nestedItem.icon}></i>
                                                            {nestedItem.label}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="navbar-right-mobile">
                        {isLoggedIn ? (
                            <>
                                <button onClick={() => navigate("/MenuPage")} className="navbar-button">
                                    <i className="pi pi-th-large"></i> Menú de opciones
                                </button>
                                <button onClick={handleLogout} className="navbar-button logout-button">
                                    <i className="pi pi-sign-out"></i> Cerrar Sesión
                                </button>
                            </>
                        ) : (
                            <button onClick={() => navigate("/LoginPage")} className="navbar-button">
                                <i className="pi pi-fw pi-user"></i> Inicio de Sesión
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="navbar-right">
                {isLoggedIn ? (
                    <>
                        <button onClick={() => navigate("/MenuPage")} className="navbar-button">
                            <i className="pi pi-th-large"></i> Menú de opciones
                        </button>
                        <button onClick={handleLogout} className="navbar-button logout-button">
                            <i className="pi pi-sign-out"></i> Cerrar Sesión
                        </button>
                    </>
                ) : (
                    <button onClick={() => navigate("/LoginPage")} className="navbar-button">
                        <i className="pi pi-fw pi-user"></i> Inicio de Sesión
                    </button>
                )}
            </div>
        </div>
    );
}

export default Navbar;
