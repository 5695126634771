import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { getAllTutorias, postTutoria, putTutoria, GetAgenda, deleteAgendaItem } from '../../Service/TutoriasService';
import { getPersonas } from '../../Service/PersonaService';
import './TableTutorship.css';

const TableTutorship = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [data, setData] = useState([]);
    const [personas, setPersonas] = useState([]);
    const [agendaData, setAgendaData] = useState([]);
    const [isModalAgendaOpen, setIsModalAgendaOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const personas = await getPersonas();
            personas.forEach(persona => {
                persona.Per_Nombre = `${persona.Per_Nombre} ${persona.Per_SNombre} ${persona.Per_PApellido} ${persona.Per_SApellido}`;
            });
            setPersonas(personas);

            const tutorias = await getAllTutorias();
            setData(tutorias);
        };
        fetchData();
    }, []);


    const onPageChange = event => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const onGlobalFilterChange = event => {
        setGlobalFilter(event.target.value);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleNew = () => {
        setSelectedItem(null);
        setIsCreating(true);
        setIsModalOpen(true);
    };

    const handleDeleteAgenda = async (rowData) => {  
        try {
            setIsModalAgendaOpen(false);
    
            Swal.fire({
                title: '¿Estás seguro?',
                text: '¿Seguro que quieres eliminar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Si, bórralo!',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    console.log('Eliminar agenda:', rowData);
                    await deleteAgendaItem(rowData.id); 
                    setAgendaData(agendaData.filter(item => item.id !== rowData.id)); 
                    Swal.fire(
                        'Eliminado!',
                        'El registro ha sido eliminado.',
                        'success'
                    );
                }
            });
        } catch (error) {
            console.error(error);
        }
    };
    
    const handleViewAgenda = async (rowData) => {
        console.log('Ver agenda para:', rowData.Id);

        try {
            const agenda = await GetAgenda(rowData.Id);  
            setAgendaData(agenda);  
            setIsModalAgendaOpen(true);  
        } catch (error) {
            console.error("Error al obtener la agenda:", error);
        }
    };

    const handleAdd = (newData) => {
        setData([...data, newData]);
    };

    const handleSumit = () => {
        if (selectedItem.Id) {
            putTutoria(selectedItem).then(() => {
                handleEditUpdate(selectedItem);
            });
        } else {
            postTutoria(selectedItem).then(() => {
                handleAdd(selectedItem);
            });
        }
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Elemento guardado exitosamente",
            showConfirmButton: false,
            timer: 1500,
        });
        handleCloseModal();
    };

    const handleEdit = (rowData) => {
        rowData.Tut_Estado = 'A';
        setSelectedItem(rowData);
        setIsCreating(false);
        setIsModalOpen(true);
    };

    const handleEditUpdate = (updatedData) => {
        setData((prevData) => {
            const newData = [...prevData];
            const dataIndex = newData.findIndex(item => item.Id === updatedData.Id);
            if (dataIndex !== -1) {
                newData[dataIndex] = updatedData;
            }
            return newData;
        });
    };

    const handleDelete = (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, bórralo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    rowData.Tut_Estado = 'I';
                    await putTutoria(rowData);
                    const updatedData = data.filter(item => item.Id !== rowData.Id);
                    setData(updatedData);
                    Swal.fire(
                        'Eliminado!',
                        'El registro ha sido eliminado.',
                        'success'
                    );
                } catch (error) {
                    console.error(error);
                }
            }
        });
    };

    const filterHeader = (
        <div className="table-filter">
            <InputText
                type="search"
                onInput={onGlobalFilterChange}
                placeholder="Buscar"
            />
            <Button
                label="Nuevo"
                icon="pi pi-plus"
                className="button-new p-button-success"
                onClick={handleNew}
            />
        </div>
    );

    const dialogFooter = (
        <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={handleCloseModal} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={handleSumit} />
        </div>
    );

    return (
        <div className="Component-datatable">

            <DataTable
                value={data}
                paginator
                rows={rows}
                first={first}
                onPage={onPageChange}
                totalRecords={data.length}
                className="p-datatable-gridlines"
                globalFilter={globalFilter}
                header={filterHeader}
                emptyMessage="No hay registros"
            >
                <Column field="nombre" header="Tutoria" sortable></Column>
                <Column field="descripcion" header="Descripcion" sortable></Column>
                <Column field="tutor" header="Tutor Acargo" sortable></Column>
                <Column field="duracion" header="Duracion" sortable></Column>
                <Column 
  header="Ver Agendas" 
  body={(rowData) => (
    <Button 
      icon="pi pi-eye" 
      className="p-button-rounded p-button-info" 
      onClick={() => handleViewAgenda(rowData)} 
    />
  )} 
/>                <Column header="Acciones" body={(rowData) => (<><Button icon="pi pi-pencil" className="p-button-rounded p-button-pencil p-mr-2" onClick={() => handleEdit(rowData)} /><Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => handleDelete(rowData)} /></>)}></Column>

            </DataTable>

            <Dialog
                header={isCreating ? 'Crear nuevo registro' : 'Editar registro'}
                visible={isModalOpen}
                onHide={handleCloseModal}
                footer={dialogFooter}
            >
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nombre">Nombre</label>
                        <InputText
                            id="nombre"
                            type="text"
                            value={selectedItem?.nombre || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, nombre: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="descripcion">Descripcion</label>
                        <InputTextarea
                            id="descripcion"
                            type="text"
                            value={selectedItem?.descripcion || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, descripcion: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="duracion">Duracion</label>
                        <InputText
                            id="duracion"
                            type="number"
                            value={selectedItem?.duracion || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, duracion: e.target.value })}
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="Per_Id">Tutor</label>
                        <Dropdown
                            id="Per_Nombre"
                            value={selectedItem?.Per_Id ? personas.find(persona => persona.Per_Id === selectedItem.Per_Id) : null}
                            options={personas.filter(persona => persona.Rol_Nombre === 'Tutor')}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_Id: e.value.Per_Id, tutor: e.value.Per_Nombre })}
                            optionLabel="Per_Nombre"
                            placeholder="Seleccione un Tutor"
                        />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header="Agenda de Tutoria"
                visible={isModalAgendaOpen}
                onHide={() => setIsModalAgendaOpen(false)}
                footer={<Button label="Cerrar" icon="pi pi-times" onClick={() => setIsModalAgendaOpen(false)} />}
            >
                <DataTable value={agendaData} className="p-datatable-gridlines">
                    <Column field="hora" header="Hora" sortable />
                    <Column field="nombre" header="Nombre" sortable />
                    <Column field="correo" header="Correo" sortable />
                    <Column field="tutoria_nombre" header="Tutoria" sortable />
                    {/* Nueva columna para eliminar la agenda */}
                    <Column
                        header="Eliminar"
                        body={(rowData) => (
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-danger"
                                onClick={() => handleDeleteAgenda(rowData)}
                            />
                        )}
                    />
                </DataTable>
            </Dialog>

        </div>
    );
}

export default TableTutorship;
