import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getPersonas, postPersona, putPersona } from '../../Service/PersonaService';
import { getRoles } from '../../Service/RolService';
import './TableUser.css';

const TableUser = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const rolesData = await getRoles();
                setRoles(rolesData);

                const personas = await getPersonas();
                console.log("Esyp sdfsf" , personas);  
                setData(personas);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const onGlobalFilterChange = (event) => {
        setGlobalFilter(event.target.value);
    };

    const handleEdit = (rowData) => {
        setSelectedItem(rowData);
        setIsCreating(false);
        setIsModalOpen(true);
    };

    const handleEditUpdate = (updatedData) => {
        setData((prevData) => {
            const newData = [...prevData];
            const dataIndex = newData.findIndex(item => item.Per_Id === updatedData.Per_Id);
            if (dataIndex !== -1) {
                newData[dataIndex] = updatedData;
            }
            return newData;
        });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleDelete = async (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, bórralo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    rowData.Per_Estado = 'I';
                    rowData.Usu_Estado = 'I';
                    const response = await putPersona(rowData);
                    if (response) {
                        const updatedData = data.filter(item => item.Per_Id !== rowData.Per_Id);
                        setData(updatedData);
                        Swal.fire(
                            'Eliminado!',
                            'El registro ha sido eliminado.',
                            'success'
                        );
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'No se pudo eliminar el registro. Por favor, intenta de nuevo.',
                        });
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Ocurrió un error inesperado. Por favor, intenta de nuevo.',
                    });
                }
            }
        });
    };

    const handleNew = () => {
        setSelectedItem(null);
        setIsCreating(true);
        setIsModalOpen(true);
    };

    const filterHeader = (
        <div className="table-filter">
            <div>
                <InputText
                    type="search"
                    onInput={onGlobalFilterChange}
                    placeholder="Buscar"
                />
            </div>
            <div className="button-new">
                <Button
                    label="Nuevo"
                    icon="pi pi-plus"
                    className="p-button-success button-new"
                    onClick={handleNew}
                />
            </div>
        </div>
    );

    const handleAdd = (newData) => {
        setData([...data, newData]);
    };

    const handleSumit = async () => {
        try {
            if (selectedItem.Per_Id) {
                console.log("dsdfsdfsdfsdfsdfsdfsdfsdf", selectedItem);

                const response = await putPersona(selectedItem);
                if (response) {
                    handleEditUpdate(selectedItem);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Usuario actualizado exitosamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un problema al actualizar el usuario. Por favor, intenta de nuevo.",
                    });
                }
            } else {
                console.log("dsdfsdfsdfsdfsdfsdfsdfsdf", selectedItem);
                const response = await postPersona(selectedItem);
                if (response) {
                    handleAdd(selectedItem);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Usuario creado exitosamente",
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un problema al crear el usuario. Por favor, intenta de nuevo.",
                    });
                }
            }
            handleCloseModal();
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error inesperado. Por favor, intenta de nuevo.",
            });
            console.error("Error en la operación:", error);
        }
    };

    const dialogFooter = (
        <div>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary" onClick={handleCloseModal} style={{ marginRight: '10px', borderRadius: '12px' }} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-success" onClick={handleSumit} style={{ borderRadius: '12px', width: '130px' }} />
        </div>
    );

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                    <ProgressSpinner />
                </div>
            ) : (
                <DataTable
                    value={data}
                    paginator
                    rows={rows}
                    first={first}
                    onPage={onPageChange}
                    totalRecords={data.length}
                    className="p-datatable-gridlines"
                    globalFilter={globalFilter}
                    header={filterHeader}
                    emptyMessage="No hay registros"
                    style={{ width: '90%', margin: '0 auto', borderRadius: '10px', overflow: 'hidden' }}
                >
                    <Column field="Per_Identificacion" header="Identificación" sortable></Column>
                    <Column field="Per_Nombre" header="Nombre" sortable></Column>
                    <Column field="Per_PApellido" header="Primer Apellido" sortable></Column>
                    <Column field="Per_SApellido" header="Segundo Apellido" sortable></Column>
                    <Column field="Usu_Usuario" header="Usuario" sortable></Column>
                    <Column field="Rol_Nombre" header="Rol" sortable></Column>
                    <Column
                        header="Acciones"
                        body={(rowData) => (
                            <>
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-rounded p-button-edit p-mr-2"
                                    onClick={() => handleEdit(rowData)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-warning"
                                    onClick={() => handleDelete(rowData)}
                                />
                            </>
                        )}
                    ></Column>
                </DataTable>
            )}
            <Dialog
                header={isCreating ? 'Crear nuevo registro' : 'Editar registro'}
                visible={isModalOpen}
                style={{ width: '90%' }}
                onHide={handleCloseModal}
                footer={dialogFooter}
            >
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="Per_Identificacion">Identificación</label>
                        <InputText
                            id="Per_Identificacion"
                            type="text"
                            value={selectedItem?.Per_Identificacion || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_Identificacion: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Per_Nombre">Nombre</label>
                        <InputText
                            id="Per_Nombre"
                            type="text"
                            value={selectedItem?.Per_Nombre || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_Nombre: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Per_SNombre">Segundo Nombre</label>
                        <InputText
                            id="Per_SNombre"
                            type="text"
                            value={selectedItem?.Per_SNombre || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_SNombre: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Per_PApellido">Primer Apellido</label>
                        <InputText
                            id="Per_PApellido"
                            type="text"
                            value={selectedItem?.Per_PApellido || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_PApellido: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Per_SApellido">Segundo Apellido</label>
                        <InputText
                            id="Per_SApellido"
                            type="text"
                            value={selectedItem?.Per_SApellido || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_SApellido: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Per_Correo">Correo</label>
                        <InputText
                            id="Per_Correo"
                            type="text"
                            value={selectedItem?.Per_Correo || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Per_Correo: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Usu_Usuario">Usuario</label>
                        <InputText
                            id="Usu_Usuario"
                            type="text"
                            value={selectedItem?.Usu_Usuario || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Usu_Usuario: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Usu_Contrasena">Contraseña</label>
                        <InputText
                            id="Usu_Contrasena"
                            type="password"
                            value={selectedItem?.Usu_Contrasena || ''}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Usu_Contrasena: e.target.value })}
                        />
                    </div>

                    <div className="p-field">
                        <label htmlFor="Rol_Id">Rol</label>
                        <Dropdown
                            id="Rol_Nombre"
                            value={selectedItem?.Rol_Id ? roles.find(role => role.Rol_Id === selectedItem.Rol_Id) : null}
                            options={roles}
                            onChange={(e) => setSelectedItem({ ...selectedItem, Rol_Id: e.value.Rol_Id, Rol_Nombre: e.value.Rol_Nombre })}
                            optionLabel="Rol_Nombre"
                            placeholder="Seleccione un rol"
                        />
                    </div>

                </div>
            </Dialog>
        </div>
    );
};

export default TableUser;
