import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Modal.css';
import Swal from 'sweetalert2'; 
import { EnviarCorreo } from '../../Service/GeneralService'; 
import { obtenerCorreosAdmin } from '../../Service/PersonaService'; 

const Modal = ({
  taller,
  onClose,
  email,
  setEmail,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  name,
  setName,
  phone,
  setPhone,
  aula,
  setAula
}) => {
  const today = new Date().toISOString().split('T')[0];
  const [correosAdmins, setCorreosAdmins] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const correos = await obtenerCorreosAdmin();
      console.log("Correos Admin ", correos);
      setCorreosAdmins(correos);
    }
    fetchData();
  }, []);

  const handleSubmit = async () => {
    // Validación de los campos
    if (!name || !email || !selectedDate || !selectedTime || !aula) {
      Swal.fire({
        title: 'Error',
        text: 'Todos los campos son obligatorios, por favor completa todos los campos.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    // Validación del formato de correo electrónico
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        title: 'Error',
        text: 'El formato del correo electrónico no es válido.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    const emailContentSolicitante = `
      **Confirmación de Solicitud de Taller**
    
      Estimado/a ${name || '[Tu Nombre]'},
    
      Muchas gracias por tu interés en el taller "${taller.Tal_Nombre}". 
      Has solicitado más información sobre este taller para el día ${selectedDate} a las ${selectedTime} en el aula ${aula}. 
    
      Nos pondremos en contacto contigo lo antes posible para proporcionarte los detalles adicionales y confirmar tu asistencia.
    
      Agradecemos tu interés y estamos a tu disposición para cualquier consulta adicional.
    
      Saludos cordiales,
      Equipo de Talleres
    `;

    const emailContentEquipo = `
      Solicitud de Taller
    
      Buen día,
    
      Se ha recibido una solicitud de información y registro para el taller "${taller.Tal_Nombre}".
      
      El solicitante, ${name || '[Tu Nombre]'}, ha mostrado interés en participar en el taller programado para el día ${selectedDate} a las ${selectedTime} en el aula ${aula}.
    
      Información del solicitante:
      - Nombre: ${name || '[Tu Nombre]'}
      - Correo: ${email}
      - Teléfono: ${phone || '[Sin número de teléfono]'}
    
      Por favor, pónganse en contacto con el solicitante para proporcionarle más detalles sobre el taller y confirmar su registro.
    
      Saludos cordiales,
      Equipo de Talleres
    `;

    const dataSolicitante = {
      to: email,
      subject: 'Confirmación de solicitud de taller',
      text: emailContentSolicitante
    };

    try {
      await EnviarCorreo(dataSolicitante);
      console.log("Correo de solicitante enviado correctamente");

      for (let admin of correosAdmins) {
        const dataEquipo = {
          to: admin.Per_Correo, 
          subject: `Solicitud de taller ${taller.Tal_Nombre}`,
          text: emailContentEquipo
        };

        try {
          await EnviarCorreo(dataEquipo);
          console.log(`Correo enviado a: ${admin.Per_Correo}`); 
        } catch (error) {
          console.error(`Error al enviar el correo a: ${admin.Per_Correo}`, error);
        }
      }

      Swal.fire({
        title: 'Solicitud enviada',
        text: 'Tu solicitud ha sido enviada exitosamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      onClose();
    } catch (error) {
      console.error("Error al enviar los correos:", error);

      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar tu solicitud, por favor intenta de nuevo.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-form">
          <h2>Solicitar Taller: {taller.Tal_Nombre}</h2>
          <p>{taller.Tal_Descripcion}</p>

          <label>Nombre :</label>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            className="modal-input"
            placeholder="Tu nombre"
          />

          <label>Teléfono (opcional):</label>
          <input
            type="tel"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            className="modal-input"
            placeholder="Tu teléfono"
          />

          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="modal-input"
          />

          <label>Fecha de interés:</label>
          <input
            type="date"
            value={selectedDate}
            onChange={e => setSelectedDate(e.target.value)}
            className="modal-input"
            min={today}
          />

          <label>Hora de interés:</label>
          <input
            type="time"
            value={selectedTime}
            onChange={e => setSelectedTime(e.target.value)}
            className="modal-input"
          />

          <label>Aula:</label>
          <input
            type="text"
            value={aula}
            onChange={e => setAula(e.target.value)}
            className="modal-input"
            placeholder="Escribe el aula"
          />

          <div className="modal-preview">

            <div className="modal-buttons">
              <button onClick={handleSubmit}>Enviar</button>
              <button onClick={onClose}>Cancelar</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  taller: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  selectedTime: PropTypes.string.isRequired,
  setSelectedTime: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  aula: PropTypes.string.isRequired, 
  setAula: PropTypes.func.isRequired 
};

export default Modal;
