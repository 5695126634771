import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();
const BASE_URL = 'https://www.srb-unainvestigacion.org:3000/documents';

/**
 * Guarda un documento o imagen en el servidor
 * @param {FormData} datos - Datos del archivo a guardar
 * @param {string} tipo - 'images' o 'uploads'
 */
export const guardarDocumento = async (datos, tipo) => {
    try {
        const response = await axios.post(`${BASE_URL}/upload/${tipo}`, datos, {
            headers: {
                Authorization: cookies.get('token'),
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error al guardar el documento:", error.response?.data || error.message);
        throw error;
    }
};

/**
 * Obtiene la lista de documentos
 */
export const obtenerDocumentos = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/list/uploads`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener documentos:", error.response?.data || error.message);
        throw error;
    }
};

/**
 * Obtiene la lista de fotos
 */
export const obtenerFotos = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/list/images`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener fotos:", error.response?.data || error.message);
        throw error;
    }
};

/**
 * Obtiene todos los archivos, tanto imágenes como documentos
 */
export const obtenerTodosLosArchivos = async () => {
    try {
        const [imagenes, documentos] = await Promise.all([
            axios.get(`${BASE_URL}/list/images`),
            axios.get(`${BASE_URL}/list/uploads`)
        ]);
        return [...imagenes.data, ...documentos.data];
    } catch (error) {
        console.error("Error al obtener todos los archivos:", error.response?.data || error.message);
        throw error;
    }
};

/**
 * Descarga un archivo del servidor
 * @param {string} file - Nombre del archivo
 * @param {string} tipo - 'images' o 'uploads'
 */
export const descargarDocumento = async (file, tipo) => {
    try {
        const response = await axios.get(`${BASE_URL}/download/${tipo}/${file}`, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
        link.remove();
        
    } catch (error) {
        console.error("Error al descargar el documento:", error.response?.data || error.message);
        throw error;
    }
};

/**
 * Elimina un archivo del servidor
 * @param {string} file - Nombre del archivo
 * @param {string} tipo - 'images' o 'uploads'
 */
export const eliminarDocumento = async (file, tipo) => {
    try {
        const response = await axios.delete(`${BASE_URL}/delete/${tipo}/${file}`, {
            headers: {
                Authorization: cookies.get('token')
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error al eliminar el documento:", error.response?.data || error.message);
        throw error;
    }
};

/**
 * Actualiza un archivo existente
 * @param {FormData} datos - Datos del nuevo archivo a subir
 * @param {string} tipo - 'images' o 'uploads'
 * @param {string} file - Nombre del archivo a reemplazar
 */
export const actualizarDocumento = async (datos, tipo, file) => {
    try {
        const response = await axios.put(`${BASE_URL}/update/${tipo}/${file}`, datos, {
            headers: {
                Authorization: cookies.get('token'),
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error al actualizar el documento:", error.response?.data || error.message);
        throw error;
    }
};
