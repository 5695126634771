import React, { useEffect, useState } from "react";
import "./MenuCalendar.css";
import { getAllTutorias } from "../../Service/TutoriasService";
import Calendar from "./Calendar";

const MenuCalendar = () => {
  const [opcionesMenu, setOpcionesMenu] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [idTutor, setIdTutor] = useState(null);
  const [tutoria, setTutoria] = useState(null);
  const [correoTutor, setCorreoTutor] = useState(null);
  const [duracion, setDuracion] = useState(null);
  const [idTutoria, setidtutoria] = useState(null);

  const sections = [
    {
      title: "¿Cómo agendar una tutoría?",
      content:
        "Para agendar una tutoría, selecciona la tutoría de tu interés, elige el día y la hora disponibles, y completa los campos de nombre y correo. Un correo se enviará automáticamente con la información de la solicitud, y será atendido por el tutor correspondiente.",
    },
    {
      title: "¿Qué tipos de tutorías ofrecemos?",
      content:
        "Ofrecemos una variedad de tutorías que cubren diferentes partes en el área de investigación. Cada tutoría es impartida por expertos en la materia, asegurando que recibas la mejor orientación posible.",
    },
    {
      title: "Beneficios de usar nuestro servicio",
      content:
        "Nuestro servicio de tutorías está diseñado para ser flexible y accesible. Puedes agendar sesiones en cualquier momento, desde cualquier lugar, y adaptarlas a tu ritmo de aprendizaje. Además, nuestro sistema de seguimiento te permite revisar tu progreso y ajustar tus sesiones según tus necesidades.",
    },
    {
      title: "Preguntas frecuentes",
      content:
        "¿Puedo cancelar una tutoría? Sí, puedes cancelar una tutoría hasta 24 horas antes de la sesión programada o reprogramarla enviando un correo al tutor correspondiente.",
    },
    {
      title: "Contacto",
      content:
        "Si tienes alguna duda o necesitas asistencia, no dudes en ponerte en contacto con nosotros. Puedes enviarnos un correo a centrodeescriturasrb@una.cr. Nuestro equipo de soporte está disponible de lunes a viernes, de 8:00 a.m. a 5:00 p.m.",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const tutorias = await getAllTutorias();
      const tutoriasList = tutorias.map((tutoria) => {
        return {
          ...tutoria,
          onClick: () => {
            setOpenCalendar(true);
            setIdTutor(tutoria.Per_Id);
            setTutoria(tutoria.nombre);
            setCorreoTutor(tutoria.correo);
            setDuracion(tutoria.duracion);
            setidtutoria(tutoria.Id);
          },
        };
      });
      setOpcionesMenu(tutoriasList);
    };
    fetchData();
  }, []);

  const handleCloseModal = () => {
    setOpenCalendar(false);
  };

  return (
    <div className="menu-calendar-container">
      <h1 className="menu-title">Información General sobre Tutorías</h1>

      <div className="info-sections">
        {sections.map((section, index) => (
          <div key={index} className="info-section">
            <h2 className="section-title">{section.title}</h2>
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>

      <h2 className="menu-title">Seleccione una Tutoría</h2>
      <div className="tutorias-list">
        {opcionesMenu.length > 0 ? (
          opcionesMenu.map((opcion, index) => (
            <div key={index} className="tutoria-item" onClick={opcion.onClick}>
              <div className="tutoria-row">
                <div className="tutoria-nombre">{opcion.nombre}</div>
                <div className="tutoria-idioma">{opcion.idioma}</div>
              </div>
              <div className="tutoria-descripcion">{opcion.descripcion}</div>
            </div>
          ))
        ) : (
          <div className="loading">No Hay tutorías Disponibles</div>
        )}
      </div>

      {openCalendar && (
        <Calendar
          idTutoria={idTutoria}
          idTutor={idTutor}
          isOpen={openCalendar}
          onClose={handleCloseModal}
          tutoria={tutoria}
          correoTutor={correoTutor}
          duracion={duracion}
        />
      )}
    </div>
  );
};

export default MenuCalendar;
