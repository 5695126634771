import React from 'react';
import Workshop from '../Componentes/Workshop/Workshop';
import Navbar from '../Componentes/Navbar/Navbar';

const WorkshopPage = () => {
  
  return (
    <div>
      <Navbar></Navbar>
      <Workshop />
    </div>
  );
};

export default WorkshopPage;
