import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WorkshopStyle.css';
import Modal from './Modal';

const Workshop = () => {
  const [talleres, setTalleres] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTaller, setSelectedTaller] = useState(null);
  const [email, setEmail] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [aula, setAula] = useState('');

  const sections = [
    {
      title: "¿Cómo inscribirse en un taller?",
      content: "Selecciona el taller de tu interés, elige una fecha, hora, aula y completa los campos de información personal. Recibirás un correo de confirmación con los detalles de tu inscripción.",
    },
    {
      title: "Tipos de talleres disponibles",
      content: "Nuestros talleres abarcan una amplia gama de temas, desde escritura creativa hasta habilidades de investigación y desarrollo profesional. Cada taller está diseñado para mejorar tus competencias y ofrecerte nuevas herramientas.",
    },
    {
      title: "Beneficios de participar en nuestros talleres",
      content: "Participar en nuestros talleres te permitirá desarrollar nuevas habilidades, conectar con otros participantes y acceder a material educativo. Además, los talleres están dirigidos por expertos en cada tema.",
    },
    {
      title: "Preguntas frecuentes",
      content: "¿Qué pasa si no puedo asistir? Puedes reprogramar tu participación para una fecha futura si lo notificas con antelación.",
    },
    {
      title: "Contacto",
      content: "Si tienes alguna duda o necesitas asistencia, por favor contáctanos a centrodeescriturasrb@una.cr. Nuestro equipo de soporte estará encantado de ayudarte.",
    }
  ];

  useEffect(() => {
    axios.get('https://www.srb-unainvestigacion.org:3000/taller/GetTaller')
      .then(response => {
        setTalleres(response.data);
      })
      .catch(error => {
        console.error('Error obteniendo la lista de talleres:', error);
      });
  }, []);

  const handleOpenModal = (taller) => {
    setSelectedTaller(taller);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTaller(null);
    setEmail('');
    setSelectedDate('');
    setSelectedTime('');
    setName('');
    setPhone('');
  };

  return (
    <div className="workshop-container">
      <h1 className="workshop-title">Información General sobre los Talleres</h1>
      <div className="info-sections">
        {sections.map((section, index) => (
          <div key={index} className="info-section">
            <h2 className="section-title">{section.title}</h2>
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>

      <h2 className="workshop-title">Lista de Talleres</h2>
      <div className="talleres-list">
        {talleres.length > 0 ? (
          talleres.map((taller) => (
            <div
              key={taller.Tal_Id}
              className="taller-item"
              onClick={() => handleOpenModal(taller)}
            >
              {taller.Tal_Nombre}
            </div>
          ))
        ) : (
          <div className="loading">Cargando...</div>
        )}
      </div>

      {showModal && (
        <Modal 
          taller={selectedTaller}
          onClose={handleCloseModal}
          email={email}
          setEmail={setEmail}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
          aula={aula}
          setAula={setAula}
        />
      )}
    </div>
  );
};

export default Workshop;
