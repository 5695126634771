import React, { useState, useEffect } from "react";
import Table from "../Componentes/Table/Table";
import Navbar from '../Componentes/Navbar/Navbar';
import { getTalleres, saveTaller, updateTaller, deleteTaller } from "../Service/TalleresService";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

const MantenimientoTallerPage = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get('token')) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    getTalleres().then(data => {
      if (data.length === 0) {
        setColumns([]);
        setData([]);
      } else {
        setColumns(Object.keys(data[0]).map(key => ({ field: key, header: key })));
        setData(data);
      }
    });
  }, []);

  const handleAdd = async (newData) => {
    const response = await saveTaller(newData);
    setData((prevData) => [...prevData, response]);  
  };

  const handleEditUpdate = async (updatedData) => {
    const response = await updateTaller(updatedData);
    setData((prevData) => {
      const updatedDataIndex = prevData.findIndex(item => item.Tal_Id === response.Tal_Id);
      const newData = [...prevData];
      if (updatedDataIndex !== -1) {
        newData[updatedDataIndex] = response; 
      }
      return newData;
    });
  };

  const handleDelete = async (rowData) => {
    const response = await deleteTaller(rowData.Tal_Id);
    if (response.success) {
      setData((prevData) => prevData.filter(item => item.Tal_Id !== rowData.Tal_Id)); 
    }
  };

  return (
    <div>
      <Navbar />
      <h1 className='titulo'>Mantenimiento Taller</h1>
      <Table
        prefijo={"Tal"}
        tabla={"ce_talleres"}
        columns={columns}
        initialData={data}
        onAdd={handleAdd}
        onEdit={handleEditUpdate}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default MantenimientoTallerPage;
