import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children, navigate }) => {
  const cookies = new Cookies();
  const initialToken = cookies.get('token') || null;
  const [token, setToken] = useState(initialToken);
  const [isLogin, setIsLogin] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef(null);
  const userActivityRef = useRef(false);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (token) {
      startCountdown(450); // 15 minutos
    }
    return () => {
      clearInterval(timerRef.current);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [token]);

  const login = (newToken) => {
    setToken(newToken);
    cookies.set('token', newToken, { path: '/' });
    setIsLogin(true);
    userActivityRef.current = false;
    startTimeRef.current = Date.now(); // Marca el inicio de sesión
  };

  const logout = () => {
    clearInterval(timerRef.current);
    setToken(null);
    cookies.remove('token');
    setIsLogin(false);
  };
  const startCountdown = (initialSeconds) => {
    let remainingTime = initialSeconds;
  
    timerRef.current = setInterval(() => {
      if (remainingTime <= 0) {
        // Si el tiempo se acaba, cierra la sesión
        clearInterval(timerRef.current);
        setToken(null);
        cookies.remove('token');
        setIsLogin(false);
        Swal.fire({
          icon: 'error',
          title: 'Sesión expirada',
          text: 'La sesión ha expirado, por favor inicia sesión de nuevo.'
        }).then(() => {
          window.location.reload();
        });
      } else {
        if (userActivityRef.current) {
          // Si hay actividad del usuario, reiniciar el contador
          remainingTime = initialSeconds; 
          userActivityRef.current = false; // Resetear la bandera de actividad
        } else {
          // Decrementar el tiempo si no hay actividad
          remainingTime--;
        }
        
        // Log para ver el tiempo restante en la consola
        console.log("Tiempo restante:", remainingTime, "segundos");
      }
    }, 1000);
  };
  
  const handleUserActivity = () => {
    userActivityRef.current = true;
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  // Efecto para calcular e imprimir el tiempo transcurrido desde el login
  useEffect(() => {
    let elapsedInterval;
    if (token && startTimeRef.current) {
      elapsedInterval = setInterval(() => {
        const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
        setElapsedTime(elapsed);
        console.log("Tiempo transcurrido:", elapsed, "segundos");
      }, 1000);
    }
    return () => {
      if (elapsedInterval) clearInterval(elapsedInterval);
    };
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, login, logout, isLogin, elapsedTime }}>
      {children}
    </AuthContext.Provider>
  );
};
