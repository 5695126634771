import React from 'react';
import { useNavigate } from "react-router-dom";
import { FaChalkboardTeacher, FaPhotoVideo, FaUserFriends, FaUsersCog, FaFileAlt, FaCogs, FaVideo, FaFileImage, FaGraduationCap } from 'react-icons/fa';
import "./Menu.css";

const Menu = () => {
    const navigate = useNavigate();

    const opcionesMenu = [
        {
            nombre: "Personas",
            icono: <FaUserFriends size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #00EBF7, #00BCD4)",
            onClick: () => navigate("/MantenimientoPersonaPage"),
        },
        {
            nombre: "Roles",
            icono: <FaUsersCog size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #F1948A, #F8BBD0)",
            onClick: () => navigate("/MantenimientoRolPage"),
        },
        {
            nombre: "Parametros",
            icono: <FaCogs size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #42DC98, #66BB6A)",
            onClick: () => navigate("/MantenimientoParametroPage"),
        },
        {
            nombre: "Tutores",
            icono: <FaChalkboardTeacher size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #E262E8, #BA68C8)",
            onClick: () => navigate("/MantenimientoTutorPage"),
        },
        
        {
            nombre: "Talleres",
            icono: <FaChalkboardTeacher size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #AA98A9, #D3CCE3)",
            onClick: () => navigate('/MantenimientoTallerPage'),
        },
        {
            nombre: "Tutorias",
            icono: <FaGraduationCap size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #F9A825, #FFD54F)",
            onClick: () => navigate("/MantenimientoTutoriasPage"),
        },

   
        {
            nombre: "Videos",
            icono: <FaVideo size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #E8D462, #FFEB3B)",
            onClick: () => navigate("/MantenimientoVideoPage"),
        },

        {
            nombre: "Archivos",
            icono: <FaFileImage size={80} />, // Aumentamos el tamaño del icono
            color: "linear-gradient(135deg, #62B5B9, #4DB6AC)",
            onClick: () => navigate("/MantenimientoArchivos"),
        }
    ];

    return (
        <div className="menu-container">
            <h1 className="menu-title">Menú Principal</h1>
            <div className="menu-page">
                {opcionesMenu.map((opcion, index) => (
                    <div className="menu-card" key={index} style={{ background: opcion.color }} onClick={opcion.onClick}>
                        {opcion.icono} {/* Aquí va el ícono en lugar de la imagen */}
                        <h2>{opcion.nombre}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Menu;
